import React, { useEffect, Component, Suspense } from "react";
import Layout from "./components/Layout/";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
  Redirect,
} from "react-router-dom";

import { UserContextProvider } from './contexts/UserContext';
import { SearchContextProvider } from './contexts/SearchContext';
import { TopicContextProvider } from './contexts/TopicContext';
import { QuestionContextProvider } from './contexts/QuestionContext';
import { OmwContextProvider } from "./contexts/OmwContext";

// Import Css
import "./Apps.scss";

import ReactGA from "react-ga4";
import { clarity } from 'react-microsoft-clarity';
import { hotjar } from 'react-hotjar';
import { MixpanelProvider } from 'react-mixpanel-browser';

// Include Routes
import routes from "./routes/allRoutes";

const MIXPANEL_TOKEN = '9098691f5a82f2563c43ea0a7401a8d8';
const MIXPANEL_CONFIG = {
  track_pageview: true, // Set to `false` by default
};

function withLayout(WrappedComponent) {
  /* eslint-disable react/display-name */
  return class extends React.Component {
    render() {
      return (
        <Layout>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

function App() {
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, 6);

  useEffect(() => {
    clarity.init(process.env.REACT_APP_CLARITY_KEY);
  }, [])

  return (
    <React.Fragment>
      <Router>
        <MixpanelProvider config={MIXPANEL_CONFIG} token={MIXPANEL_TOKEN}>
          <UserContextProvider>
            <SearchContextProvider>
              <TopicContextProvider>
                <QuestionContextProvider>
                  <OmwContextProvider>
                    <Suspense fallback={Loader}>
                      <Switch>
                        {
                          routes.map((route, idx) =>
                            <Route
                              path={route.path}
                              exact
                              component={withLayout(route.component)}
                              key={idx}
                            />
                        )}
                      </Switch>
                    </Suspense>
                  </OmwContextProvider>
                </QuestionContextProvider>
              </TopicContextProvider>
            </SearchContextProvider>
          </UserContextProvider>
        </MixpanelProvider>
      </Router>
    </React.Fragment>
  );
}

export default withRouter(App);
