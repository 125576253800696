import React, { useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';

import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <React.Fragment>       
      <footer className="footer">
        <div className="footer-py-16">
          <Container className="text-center">
            <Row className="align-items-center" style={{ marginLeft: '0px', marginRight: '0px'}}>
              <Col sm={12}>
                <div className="text-sm-start">
                  <p className="mb-0" style={{ textAlign: 'center' }}><Link to="/privacy-policy" className="footer-orange">privacy</Link> + <Link to="/terms-of-service" className="footer-orange">terms</Link> | 
                    
                    plzsend.help © {(new Date().getFullYear())} by <a href="https://twitter.com/atunasashimi" target="_blank" rel="noreferrer" className="footer-orange">@atunasashimi</a>. good vibes only ❤️
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
