import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

import RangeSlider from "react-range-slider-input";
import 'react-range-slider-input/dist/style.css';

// import logoImage from '../assets/images/plzsendhelp-logo-256.jpg';
import profileImage from '../assets/images/profile.jpg';
import twitterIcon from '../assets/images/logo-twitter.png';
import discordImage from '../assets/images/discord-icon.png';
import omwBordered from '../assets/images/omw-600.jpg';
import Swal from 'sweetalert2'

import axios from 'axios';

import EmailCta from '../components/EmailCta'
import HomeHero from "../components/HomeHero"
import HomeQuestions from "../components/HomeQuestions"
import HomeJourney from "../components/HomeJourney"
import { useHistory } from 'react-router-dom';

import { UserContext } from "../contexts/UserContext"

import Cookies from 'js-cookie';

const Home = () => {
  const [sliderValue, setSliderValue] = useState([0, 0]);
  const [mrrText, SetMrrText] = useState("< $100 MRR")
  const [costText, setCostText] = useState("💸 Free 💸")
  const history = useHistory();

  const { token, setToken } = useContext(UserContext);

  useEffect(() => {
    const storedToken = localStorage.getItem('token')

    if (storedToken) {
      setToken(storedToken)
      Cookies.set('token', storedToken, { domain: '.plzsend.help', path: '/' });
    }
  }, [])

  const [faqOpen, setFaqOpen] = useState('');
  const toggleFaq = (id) => {
    if (faqOpen === id) {
      setFaqOpen();
    } else {
      setFaqOpen(id);
    }
  };
  
  useEffect(() => {
    document.title = 'plzsend.help - your public mentor';

    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    switch (sliderValue[1]) {
      case 0: // $0
        SetMrrText("$0 MRR")
        setCostText("Free")
        break;
      case 1: // $0-$250
        SetMrrText("< $250 MRR")
        setCostText("Free!")
        break;
      case 2: // $250-$500
        SetMrrText("$250-$500 MRR")
        setCostText("💸 Free! 💸")
        break;
      case 3: // $500-$1K
        SetMrrText("$500-$1K MRR")
        setCostText("🤩 Free! 🤩")
        break;
      case 4: // 1K-2K
        SetMrrText("$1K-$2K MRR")
        setCostText("💵 Free! 💵")
        break;
      case 5: // 2K-3K
        SetMrrText("$2K-$3K MRR")
        setCostText("🤑 Free! 🤑")
        break;
      case 6: // 3K-4K
        SetMrrText("$3K-$4K MRR")
        setCostText("🔥 Free! 🔥");
        break;
      case 7: // 4K-6K
        SetMrrText("$4K-$6K MRR")
        setCostText("💰 Free! 💰")
        break;
      case 8: // 6K-8K
        SetMrrText("$6K-$8K MRR")
        setCostText("📈 Free! 📈")
        break;
      case 9: // 8K-10K
        SetMrrText("$8K-$10K MRR")
        setCostText("😱 Free!?! 😱")
        break;
      case 10: // $10k
        SetMrrText("$10K+ MRR")
        setCostText("🚀 Still Free! 🚀")
        break;
    }
  }, [sliderValue])

  // const handleRedirectToLogin = () => {
  //   history.push("/login")
  // }

  // const showLogin = process.env.NODE_ENV === 'production' ? false : true

    return (
      <React.Fragment>
        {token ? (
          <HomeQuestions />
        ) : (
          <HomeHero />
        )}

        {token ? <HomeJourney /> : (
          <React.Fragment>
            <section style={{position: 'relative'}}>
              <div className="custom-shape-divider-top-1687718512">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                </svg>
            </div>
            </section>
            </React.Fragment>
        )}
        

    
  

    <section className="sec-intro">
        <Container>
            <Row className="align-items-center justify-content-center text-center d-block d-sm-none">
              <Col xs={12}>
                <a href="/omw" target="_blank" rel="noreferrer" style={{color: "#212121"}}>
                  <h4 className="omw-header">
                    a free group mentorship program?!?
                  </h4>
                  <h4 className="omw-subheader mt-2">
                    hell yeah, join now! 🤘
                  </h4>
                </a>
              </Col>
              <Col xs={{ size: 4, offset: 4 }} style={{ marginTop: '30px'}}>
                <a href="/omw" target="_blank" rel="noreferrer"><img className="omw-bordered" src={omwBordered} alt="on my way"/></a>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center d-none d-sm-block">
              <Col xl={{size: 6, offset: 3}} lg={{size: 8, offset: 2}} md={{size: 10, offset: 1 }} sm={12}>
                <Row className="align-items-center justify-content-center">
                  <Col sm={9}>
                    <a href="/omw" target="_blank" rel="noreferrer" style={{color: "#212121"}}>
                      <h4 className="omw-header">
                      a free group mentorship program?!?
                      </h4>
                      <h4 className="omw-subheader">
                      hell yeah, join now! 🤘
                      </h4>
                    </a>
                  </Col>
                  <Col sm={3}>
                    <a href="/omw" target="_blank" rel="noreferrer"><img className="omw-bordered" src={omwBordered} alt="on my way"/></a>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Col lg={{ size: 8, offset: 2 }} md={{ size: 10, offset: 1}}>

              <Row className="align-items-center justify-content-center text-center info-box margin-mobile">
                <Col md={12} style={{ marginBottom: '24px'}}>
                  <span className="subheading">Building a startup is hard.</span>
                  <span className="subheading">👋 We're here to help 👋</span>
                </Col>
                <Col md={10}>
                  <p className="box-heading mt-2">
                    Finding users, making money, managing priorities, nothing is ever simple.
                  </p>
                  <p className="box-heading mt-2">
                    plzsend.help is an initiative to help answer all your startup questions, from starting to scaling. 
                  </p>
                  <p className="box-heading mt-2">
                    And to do that, we're mentoring founders, 100% completely free.
                  </p>
                  <p className="box-footer mt-4">
                    for the public, by the public, in public
                  </p>
                </Col>
              </Row>
            </Col>
        </Container>
    </section>

<section style={{position: 'relative'}}>
      <div className="custom-shape-divider-top-1687718512-2">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
        </svg>
    </div>
    </section>

    <section className="sec-why">
        <Container>
            <Row className="align-items-center justify-content-center margin-mobile">
                <Col lg={8} md={8} sm={12}>
                  <Row>
                  <Col md={12}>
                      <div className="card mt-2 mb-2 d-none d-lg-block">
                        {/* d-block d-md-none */}
                        {/* d-none d-md-block */}
                        <Row>
                          <Col md={8}>
                          <h4 className="title">
                              Hey, I'm Tom! 👋
                            </h4>
                            <p className="subtitle mt-2 mb-2">
                              I created plzsend.help to give equal opportunities to the founders that need it the most. 
                            </p>
                            <p className="subtitle mt-2 mb-2">
                              I've built organizations up to $30M+, helped raise millions of dollars and served hundreds of thousands of happy customers. 
                            </p>
                            <p className="subtitle mt-4 mb-2">
                              And now I'm here to help you.
                            </p>
                          </Col>
                          <Col md={4} className="align-items-center justify-content-center margin-mobile" style={{ display: 'flex' }}>
                          <img src={profileImage} style={{ width: '100%', borderRadius: '24px' }} alt="atunasashimi" />
                          </Col>
                        </Row>
                      </div>
                      <div className="card mt-2 mb-2 d-block d-lg-none">
                        <Row>
                        <Col md={{ size: 4, offset: 4 }} xs={{ size: 6, offset: 3 }} className="align-items-center justify-content-center">
                            <img src={profileImage} style={{ width: '100%', borderRadius: '24px' }} alt="atunasashimi" />  
                          </Col>
                          <Col sm={12}>
                            <h4 className="title mt-3 mb-3">
                              Hey, I'm Tom! 👋
                            </h4>
                            <p className="subtitle mt-2 mb-2">
                              I created plzsend.help to give equal opportunities to the founders that need it the most. 
                            </p>
                          </Col>

                          <Col sm={12}>
                            <p className="subtitle mt-2 mb-2">
                              I've built organizations up to $30M+, helped raise millions of dollars and served hundreds of thousands of happy customers. 
                            </p>
                            <p className="subtitle mt-2 mb-2">
                              And now I'm here to help you.
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="card mt-2 mb-2">
                        <h4 className="title">
                          But why?
                        </h4>
                          <p className="subtitle mt-2 mb-2">
                            I've gone through things you don't even know exist yet, and I want to make your life easier when you get there.
                          </p>
                          <p className="subtitle mt-2 mb-2">
                            I believe in the distribution of knowledge, and in our world where expertise is abundant, it's kept behind paywalls and the closed doors of professionals.
                          </p>
                          <p className="subtitle mt-2 mb-2">
                            plzsend.help is breaking tradition and fostering the growth of our brightest budding entrepreneurs in a new world of free professional education.
                          </p>
                          <p className="subtitle mt-2 mb-2">
                            Everyone needs someone in their corner when they're starting out.
                          </p>
                          <p className="subtitle mt-2 mb-2">
                            Let that be me.
                          </p>
                      </div>
                    </Col>
                  </Row>
                    
                </Col>
                <Col lg={4} md={4} sm={12}>
                  {/* <Row className="align-items-center justify-content-center margin-mobile">
                    <Col md={12}> */}
                      <div className="card mt-3 mb-3">
                        <h5>
                          I advise on
                        </h5>
                        <ul className="unstyled-list mb-0">
                          <li>🏆 Leadership</li>
                          <li>
                            🏗️ Founder Mindset
                          </li>
                          <li>
                          💡 Product Development
                          </li>
                          <li>
                          ⚙️ Engineering
                          </li>
                          <li>
                          👥 Organizational Scaling
                          </li>
                          <li>
                          📈 Revenue/Growth
                          </li>
                          <li>
                          👏 Accountability
                          </li>
                        </ul>
                      </div>
                    {/* </Col>
                    <Col md={12}> */}
                      <div className="card mt-3 mb-3" style={{ marginTop: '8px', marginBottom: '8px'}}>
                        <h5>
                          Where I won't go
                        </h5>
                        <ul className="no-go-list mb-0">
                          <li>Legal, regulatory, compliance</li>
                          <li>
                            Developing your product
                          </li>
                          <li>
                            UI/UX or designing your product
                          </li>
                          <li>
                            Writing sales copy
                          </li>
                        </ul>
                      </div>
                    {/* </Col>
                    <Col md={12}> */}
                      <div className="card mt-3 mb-3" style={{ marginTop: '8px', marginBottom: '8px'}}>
                        <h5>
                          Wanna join the team?
                        </h5>
                        <ul className="unstyled-list mb-0">
                          <li>📧 <a href="mailto:support@plzsend.help">Email</a> us!</li>
                          <li>
                            <img src={twitterIcon} style={{ position: 'relative', top: '-2px', width: '16px' }} alt="@atunasashimi on Twitter"/>
                            {' '} Or poke <a href="https://twitter.com/atunasashimi" target="_blank" rel="noreferrer">Tom</a> on Twitter/𝕏
                          </li>
                        </ul>
                      </div>
                    {/* </Col>
                  </Row> */}
                </Col>
              </Row>
              
        </Container>
    </section>

    {token ? <React.Fragment>
      <section style={{position: 'relative'}}>
        <div className="custom-shape-divider-top-1692721691">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
          </svg>
      </div>
    </section>
      </React.Fragment> : 
    <React.Fragment>
      <section style={{position: 'relative'}}>
    <div className="custom-shape-divider-top-1687720502">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
    </svg>
  </div>
      </section>

      <section className="sec-intro">
          <Container>
          <Row className="align-items-center justify-content-center text-center margin-mobile" style={{ marginTop: '48px' }}>
                <Col md={12}>
                  <p className="heading" style={{ marginBottom: '0px' }}>
                    If you want to know more, sign up below and let's talk!
                  </p>
                </Col>

                <Col lg={8} md={10} className="align-self-start">
                  <EmailCta buttonText={"lets talk!"}/>
                </Col>

                <Col md={12}>
                  <p className="subcta-text mt-3 mb-0">Or chat with us on <a href="/discord" target="_blank" rel="noreferrer" style={{ fontWeight: 500 }}>Discord <img src={discordImage} alt="Join our Discord!" style={{ width: '20px', top: '-2px', position: 'relative', marginLeft: '2px'}}/></a></p>
                </Col>
              </Row>
          </Container>
      </section>


      <section style={{position: 'relative'}}>
      <div className="custom-shape-divider-top-1687720502-2">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
      </svg>
  </div>
    </section>
    </React.Fragment>}

    <section className="sec-money">
      <Container>
        <Row className="align-items-center justify-content-center margin-mobile">
          <Col lg={8} md={10}>
            <div className="card mt-5 mb-4 pb-2">
                <h4 className="title" style={{ marginBottom: '24px' }}>
                  MRR to Mentorship Cost Evaluator
                </h4>

                <h5>Your revenue:</h5>
                <h5>{mrrText}</h5>

                <label htmlFor="range-slider-gradient" style={{ display: 'none' }  }>MRR:</label>
                <RangeSlider
                  id="range-slider-gradient"
                  className="margin-lg"
                  min={0}
                  max={10}
                  step={1}
                  value={sliderValue}
                  onInput={setSliderValue}
                  thumbsDisabled={[true, false]}
                  rangeSlideDisabled={true}
                />

                <h5 style={{ marginTop: '24px' }}>Your cost:</h5>
                <h5>{costText}</h5>

                <p className="subtitle mt-4">
                  <b style={{ fontWeight: '500'}}>So what does free get you?</b>
                </p>

                <p className="subtitle mt-1">
                  If you come with direct and specific questions to problems you're facing, I'll do my best to discuss, understand and assist however possible
                </p>

                <p className="subtitle mt-1">
                  As you can imagine, I don't have the bandwidth to answer generic questions like "I'm just looking for a marketing plan".
                </p>

                <p className="subtitle mt-1">
                  Tell me what you're up to, how long you've been at it, what you've tried and where you're looking for guidance.
                </p>

                <p className="subtitle mt-1">
                  Then we can talk!
                </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{ position: 'relative' }}>
      <div className="custom-shape-divider-top-1687778966">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
        </svg>
    </div>
    </section>

    <section className="sec-faq py-4 margin-mobile" style={{ backgroundColor: '#ffffff', borderRadius: '36px', marginTop: '24px'}}>
      <Container>
          <Row className="justify-content-center mt-4 p2-4 margin-mobile">
            <Col className={"col-10"}>
              <div className="mb-0 pb-2">
                <h4 className="title mb-1"> Frequently Asked Questions</h4>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center mt-4 margin-mobile">
              <Col md={10}>
                <div>
                  <Accordion flush open={faqOpen} toggle={toggleFaq}>
                    <AccordionItem>
                      <AccordionHeader targetId="1">Why are you doing this?</AccordionHeader>
                      <AccordionBody accordionId="1">
                        <p>Every founder needs support at some point, but that can be hard to find.</p>
                        <p>A lot of potential is squandered because mentorship is ungodly expensive for the ones that can't afford it.</p>
                        <p>So I'm changing that</p>
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionHeader targetId="2">But how can you operate with no money?</AccordionHeader>
                      <AccordionBody accordionId="2">
                        <p>Our team is surviving through other means, so it's not a major worry. Ask us again in a year and let's see what we say.</p>
                        <p>We're not here to squeeze you for your money. Keep it, you'll need it.</p>
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionHeader targetId="3">What's in it for you?</AccordionHeader>
                      <AccordionBody accordionId="3">
                        <p>We have the ability to change the dynamic of mentorship, so we're going at it in the most unlikely and unscalable way we could think of.</p>
                        <p>What we don't have is reach, which is why we need your help to spread the word!</p>
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionHeader targetId="4">Will you work with me?</AccordionHeader>
                      <AccordionBody accordionId="4">
                        <p>It really depends on our dynamic. We want to work with good, authentic and motivated individuals moreso than hot, cool products.</p>
                        <p>That said, if it's apparent that we share very different interests, then it may not work out.</p>
                        <p>Sign up and let's find out!</p>
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionHeader targetId="5">What are your guarantees?</AccordionHeader>
                      <AccordionBody accordionId="5">
                        <p>No guarantees.</p>
                        <p>Our teams communication and advice is based on our experience and limited understanding of you and your project. It is up to you to fully judge how applicable our suggestions are to your situation.</p>
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionHeader targetId="6">Will you be honest with me?</AccordionHeader>
                      <AccordionBody accordionId="6">
                        <p>It's not in anyone's best interests to continue floating a project destined for failure, so if we need to tell you, we'll tell you.</p>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="7">Is EVERYTHING public?</AccordionHeader>
                      <AccordionBody accordionId="7">
                        <p>Not everything, no. We condense our conversations into refined and specific topics, so none of your private, personal or business-specific info would get included.</p>
                        <p>We want to publicize the general concepts and ideas to help others, but we recognize the importance of secrecy for your private matters, and will not share those.</p>
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionHeader targetId="8">Will you schedule a call with me?</AccordionHeader>
                      <AccordionBody accordionId="8">
                        <p>We usually do the first few interactions through DMs/emails in order to help us fully understand what you're doing and where you want to go.</p>
                        <p>After a couple back and forths, if it makes sense, we may offer to set up a call and talk it out.</p>
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionHeader targetId="9">Can you be my dedicated mentor?</AccordionHeader>
                      <AccordionBody accordionId="9">
                        <p>If you're looking for regularly scheduled phone/video calls and project/business analyses, that's a significantly deeper topic.</p>
                        <p>Our commitment to any individual will happen on a case-by-case basis, but that's an offer that we would make only on the rare occasion.</p>
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionHeader targetId="10">I need help immediately, plz help!</AccordionHeader>
                      <AccordionBody accordionId="10">
                        <p>Unfortunately we can't offer expedited assistance. We're here to help on a grander scale, and if there's an emergency, our impact and timeliness may be limited.</p>
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionHeader targetId="11">Can you teach me everything?</AccordionHeader>
                      <AccordionBody accordionId="11">
                        <p>While technically we could try, it's hard to help someone unless they know exactly what they need. We can only give generic answers for generic questions.</p>
                        <p>It's recommended to come with a specific expectation of what you want to ask, and what you want to get out of it.</p>
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionHeader targetId="12">So what exactly can you help me with?</AccordionHeader>
                      <AccordionBody accordionId="12">
                        <p>To name a few things:</p>
                        <ul>
                          <li>product idea validation</li>
                          <li>handling customers</li>
                          <li>crafting your customer experience</li>
                          <li>engineering and development related concerns</li>
                          <li>project planning</li>
                          <li>raising money</li>
                          <li>accelerator program applications</li>
                          <li>organizational goals</li>
                          <li>but most importantly, building yourself as a resiliant, capable and effective founder.</li>
                        </ul>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
    </section>

    {token ? '' : <React.Fragment>
      <section className="sec-intro" style={{ marginTop: '40px' }}>
        <Container>

        <Row className="align-items-center justify-content-center text-center margin-mobile" style={{ marginTop: '48px' }}>
              <Col md={12}>
                <p className="heading" style={{ marginBottom: '0px' }}>
                  Still curious? Reach out and let's talk it through!
                </p>
              </Col>

              <Col lg={8} md={10} className="align-self-start">
               <EmailCta buttonText={"lets talk!"}/>
              </Col>

              <Col md={12}>
                  <p className="subcta-text mt-3 mb-0">Or chat with other founders on <a href="/discord" target="_blank" rel="noreferrer" style={{ fontWeight: 500 }}>Discord <img src={discordImage} alt="Join our Discord!" style={{ width: '20px', top: '-2px', position: 'relative', marginLeft: '2px'}}/></a></p>
                </Col>
            </Row>
        </Container>
    </section>
    </React.Fragment>
    }
    </React.Fragment>
  );
}

export default Home;
