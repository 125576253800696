import apfp0 from '../assets/images/apfp-0.jpeg';
import apfp1 from '../assets/images/apfp-1.jpeg';
import apfp2 from '../assets/images/apfp-2.jpeg';
import apfp3 from '../assets/images/apfp-3.jpeg';
import apfp4 from '../assets/images/apfp-4.jpeg';
import apfp5 from '../assets/images/apfp-5.jpeg';
import apfp6 from '../assets/images/apfp-6.jpeg';
import apfp7 from '../assets/images/apfp-7.jpeg';
import apfp8 from '../assets/images/apfp-8.jpeg';
import apfp9 from '../assets/images/apfp-9.jpeg';
import apfpa from '../assets/images/apfp-a.jpeg';
import apfpb from '../assets/images/apfp-b.jpeg';
import apfpc from '../assets/images/apfp-c.jpeg';
import apfpd from '../assets/images/apfp-d.jpeg';
import apfpe from '../assets/images/apfp-e.jpeg';
import apfpf from '../assets/images/apfp-f.jpeg';

// Shitty but who cares
export const anonymousPfpSelector = (id) => {
    const firstChar = id[0]
    switch(firstChar) {
        case '0':
            return apfp0;
        case '1':
            return apfp1;
        case '2':
            return apfp2;
        case '3':
            return apfp3;
        case '4':
            return apfp4;
        case '5':
            return apfp5;
        case '6':
            return apfp6;
        case '7':
            return apfp7;
        case '8':
            return apfp8;
        case '9':
            return apfp9;
        case 'a':
            return apfpa;
        case 'b':
            return apfpb;
        case 'c':
            return apfpc;
        case 'd':
            return apfpd;
        case 'e':
            return apfpe;
        case 'f':
            return apfpf;
    }
};