import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

import Swal from 'sweetalert2'
import logoImage from '../assets/images/plzsendhelp-logo-256.jpg';

import { useHistory } from 'react-router-dom';

import axios from 'axios';

const Login = () => {
    const [email, setEmail] = useState('')
    const [buttonSubmitting, setButtonSubmitting] = useState(false);
    const history = useHistory();
    
    useEffect(() => {
        document.title = 'Login - plzsend.help';
    
        window.scrollTo(0, 0);
      }, [])

      const sendLoginRequest = async (email) => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API}/email/request`, {
            email: email
          }).then((response) => {
            const message = response.data.message
            setButtonSubmitting(false);
            setEmail('')
            Toast.fire({
              icon: 'success',
              title: message
            })
          })
        } catch (error) {
          Toast.fire({
            icon: 'warning',
            title: 'An error occured, please email us at support@plzsend.help!'
          })
          setButtonSubmitting(false);
          setEmail('')
        }
      }

      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          submitEmail(event)
        }
      }
      
      const validateEmail = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

      const submitEmail = (event) => {
        if (email === "") {
          Toast.fire({
            icon: 'warning',
            title: "Please enter an email!"
          })
          return;
        }
    
        if (!validateEmail(email)) {
          Toast.fire({
            icon: 'warning',
            title: "Please recheck email format!"
          })
          return;
        }
    
        setButtonSubmitting(true);
        sendLoginRequest(email)
      }

      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-right',
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      })

    const handleGoHome = () => {
        history.push("/")
    }

    return (
        <React.Fragment>
            <section className="sec-login align-items-center">
            <img className="psh-logo" src={logoImage} alt="plzsend.help" onClick={handleGoHome} style={{ cursor: 'pointer' }}/>
                <Container className="container z-index-1">
                    <Row className="align-items-center justify-content-center margin-mobile">
                        <Col xl={4} lg={5} md={6} sm={10} xs={12} className="align-self-start">
                            <div className="card mt-2 mb-2">
                                <h5 className="mb-4">
                                    Login via email
                                </h5>
                                    <label htmlFor="cta-hero" style={{ display: 'none' }  }>Email:</label>
                                    <input
                                        id="cta-hero"
                                        className="cta-input mb-3"
                                        type="email"
                                        placeholder="your@email.here"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <button
                                        className="btn me-1 fw-500 cta-btn"
                                        onClick={submitEmail}
                                        disabled={buttonSubmitting}
                                        >send login link</button>
                            </div>
                            <p className="subcta-text mb-4 text-center">If you're subscribed to us, you'll receive a link in your email to log you in!</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section style={{position: 'relative'}}>
    <div className="custom-shape-divider-top-1687720503">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
    </svg>
</div>
    </section>


            <section className="sec-footer-fill">
            </section>
        </React.Fragment>
    )
}

export default Login