import React, { useContext, useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
  } from "reactstrap";

  import logoImage from '../assets/images/plzsendhelp-logo-256.jpg';
  import discordImage from '../assets/images/discord-icon.png';
  import pfp1 from '../assets/images/pfp1min.jpg';
  import pfp2 from '../assets/images/pfp2min.jpg';
  import pfp3 from '../assets/images/pfp3min.jpg';
  import pfp4 from '../assets/images/pfp4min.jpg';
  import pfp5 from '../assets/images/pfp5min.jpg';
  import yellowImage from '../assets/images/yellow.png';

  import { UserContext } from "../contexts/UserContext"
  import EmailCta from '../components/EmailCta'

  import { useHistory } from 'react-router-dom';

export const HomeHero = () => {
    const { token, setToken } = useContext(UserContext);
    const history = useHistory();

    const handleRedirectToLogin = () => {
        history.push("/login")
    }

    // const showLogin = process.env.NODE_ENV === 'production' ? false : true
    const showLogin = true

    const founderCount = "330"

    return(
        <section className="bg-home sec-hero align-items-center" id="home">
          <img className="psh-logo" src={logoImage} alt="plzsend.help"/>
          {showLogin && !token && (
              <React.Fragment>
                <span className="login" onClick={handleRedirectToLogin} style={{ cursor: 'pointer'}}>login</span>
              </React.Fragment>
              
            )
          }

          <Container className="container z-index-1">
            <Row className="align-items-center justify-content-center text-center margin-mobile">
                <Col lg={8} md={10} className="align-self-start">
                    <div className="title-heading text-fade-in-slide-up">
                        <h4 className="header mb-3">let's solve your startup struggles</h4>
                          <p className="subtext mb-0">free 1 on 1 mentoring to build you, your startup and your dreams</p>

                            <EmailCta buttonText={"lets talk!"}/>

                            <div className="d-block d-md-none">
                              <div className="bubble-pfp">
                                <img className="pos-0" src={pfp1} alt="" />
                                <img className="pos-1" src={pfp2} alt="" />
                                <img className="pos-2" src={pfp3} alt="" />
                                <img className="pos-3" src={pfp4} alt="" />
                                <img className="pos-4" src={pfp5} alt="" />
                                <img className="pos-count" src={yellowImage} alt="" />
                                <p className="text-count">{founderCount}+</p>
                              </div>

                              <p className="subcta-text mt-4 mb-0">join {founderCount}+ founders building their startups!</p>

                              {/* TODO: Add this to both links <a href="/omw" target="_blank" rel="noreferrer" style={{fontWeight: 500}}></a> 
                              Or is that too much in terms of CTAs? */}

                              <p className="subcta-text mt-1 mb-0">
                                  get free access to our knowledge base, group programs, and <a href="/discord" target="_blank" rel="noreferrer" style={{ fontWeight: 500 }}>discord<img src={discordImage} alt="Join our Discord!" style={{ width: '20px', top: '-2px', position: 'relative', marginLeft: '2px'}}/></a>
                              </p>
                            </div>
                            
                            <div className="d-none d-md-block">
                              <div className="bubble-pfp">
                                <img className="pos-0" src={pfp1} alt="" />
                                <img className="pos-1" src={pfp2} alt="" />
                                <img className="pos-2" src={pfp3} alt="" />
                                <img className="pos-3" src={pfp4} alt="" />
                                <img className="pos-4" src={pfp5} alt="" />
                                <img className="pos-count" src={yellowImage} alt="" />
                                <p className="text-count">{founderCount}+</p>

                                <p className="subcta-text md-offset mb-0" style={{ display: 'inline'}}>join {founderCount}+ founders building their startups!</p>
                              </div>

                              <p className="subcta-text mt-3 mb-0">get free access to our knowledge base, group programs, and <a href="/discord" target="_blank" rel="noreferrer" style={{ fontWeight: 500 }}>discord<img src={discordImage} alt="Join our Discord!" style={{ width: '20px', top: '-2px', position: 'relative', marginLeft: '2px'}}/></a></p>
                            </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </section>
    )
}

export default HomeHero;