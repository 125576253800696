import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { baseApiUrl, jwtAuthAxios, setAuthToken, getAuthToken } from "../services/request";

export const QuestionContext = React.createContext();

export const QuestionContextProvider = ({ children }) => {

    const [questionId, setQuestionId] = useState(null)
    const [username, setUsername] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [questionContent, setQuestionContent] = useState('')
    const [questionTitle, setQuestionTitle] = useState('')
    const [answerContent, setAnswerContent] = useState('');
    const [relatedQuestions, setRelatedQuestions] = useState([]);
    const [questionLoaded, setQuestionLoaded] = useState(false);

    useEffect(() => {
        const fetchQuestion = async () => {
            if (questionId) {
                await retrieveQuestion();
                setQuestionLoaded(true);
            }
        };
    
        fetchQuestion();
    }, [questionId])

    const retrieveQuestion = async () => {
        // Something sucks here and needs to be fixed
        // To enable this
        // let headers = {}
        // const token = getAuthToken()
        // if (token) {
        //     headers = {
        //         headers: { Authorization: `Bearer ${getAuthToken()}` }
        //     }
        // }

        const {data} = await jwtAuthAxios.get(`/questions/${questionId}`)

        setQuestionTitle(data.title);
        setQuestionContent(data.content)
        setUsername(data.username)
        setProfileImage(data.profile_image);
        setAnswerContent(data.answer)
        setRelatedQuestions(data.related)
    }

    const state = {
        username: username,
        profileImage: profileImage,
        questionTitle: questionTitle,
        questionContent: questionContent,
        answerContent: answerContent,
        relatedQuestions: relatedQuestions,
        setQuestionId: setQuestionId,
        questionLoaded: questionLoaded,
        setQuestionLoaded: setQuestionLoaded,
    }

    return (
        <QuestionContext.Provider value={state}>
            {children}
        </QuestionContext.Provider>
    )
}