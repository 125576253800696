import React, { useContext, useState, useEffect } from "react";
import Masonry from 'react-masonry-css';
import {
    Container,
    Row,
    Col,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
  } from "reactstrap";

  import logoImage from '../assets/images/plzsendhelp-logo-256.jpg';
  import discordImage from '../assets/images/discord-icon.png';
  import omwBordered from '../assets/images/omw-600.jpg';

  import { SearchContext } from "../contexts/SearchContext"
  import { UserContext } from "../contexts/UserContext"
  import { TopicContext } from "../contexts/TopicContext"
  import { QuestionContext } from "../contexts/QuestionContext"

  import { useHistory } from 'react-router-dom';

  import { anonymousPfpSelector } from '../services/pfp-selector'

export const HomeQuestions = () => {
    const [searchText, setSearchText] = useState('');
    const [masonryBreakpoints, setMasonryBreakpoints] = useState({});
    const [selectedTopicId, setSelectedTopicId] = useState('');
    const [columnWidth, setColumnWidth] = useState(12);
    const [loading, setLoading] = useState(true);

    const { token, omwParticipant, omwOnboarded } = useContext(UserContext);
    const { topics, retrieveTopics } = useContext(TopicContext);
    const { activeQuery, activeTopicId, setActiveTopicId, questions, retrieveLandingQuestions, requestSearch, requestTopicSearch } = useContext(SearchContext);
    const { setQuestionId } = useContext(QuestionContext)
    const history = useHistory();

    useEffect(() => {
        setActiveTopicId('')
        retrieveLandingQuestions();
        setQuestionId('')
        setTimeout(async () => {
            setLoading(false);
        }, 600)
    }, [])

    useEffect(() => {
        if (topics.length === 0) {
            retrieveTopics();
        }
    }, [topics])

    // Need to do responsive on smaller screens and mobile
    useEffect(() => {
        setMasonryBreakpoints({
            default: 3,
            992: 2,
            650: 1
        })
        setColumnWidth(12);
    }, [questions])

    const handleRedirectToLogin = () => {
        history.push("/login")
    }
    const showLogin = process.env.NODE_ENV === 'production' ? false : true

    // Extract this
    const formatQuestion = (questionContent) => {
        if (questionContent) {
            // Split the text into paragraphs
            const paragraphs = questionContent.split('\n\n');

            // Wrap each paragraph with a <p> tag
            const formattedText = paragraphs.map((paragraph, index) => <p className="subtitle mt-2 mb-2" key={index}>{paragraph}</p>);

            return formattedText;  
        }
    }

    // TODO: Add a character limit to the search field. 
    const submitSearch = async (e) => {
        if (loading === true || searchText === undefined || searchText === null || searchText === '' || searchText.length < 3) {
            return;
        }

        setSelectedTopicId('')

        setLoading(true)

        setTimeout(async () => {
            await requestSearch(searchText)

            setTimeout(async () => {
                setLoading(false);
            }, 100)    
        }, 400); 

        // When a search happens, the topic selection is wiped and vice versa
    }

    const handleKeyDown = (event) => {
        if (loading === true) {
            return;
        }

        if (event.key === 'Enter') {
          submitSearch(event)
        }
      }

    const selectTopic = async (itemId) => {
        if (loading === true) {
            return;
        }

        if (itemId !== selectedTopicId) {
            setSearchText('')
            setSelectedTopicId(itemId)

            // When a category selection happens, the search is wiped and vice versa

            setLoading(true)
            
            setTimeout(async () => {
                await requestTopicSearch(itemId)

                setTimeout(async () => {
                    setLoading(false);
                }, 100)    
            }, 400); 
        }
    }

    // Remove this when omw is live
    const omwLive = false

    const goToOmw = (e) => {
        e.preventDefault();

        if (!omwParticipant) {
            history.push("/omw")
            return;
        } else if (omwParticipant && !omwOnboarded) {
            history.push("/omw/onboarding/intro")
            return;
        } else if (omwParticipant && omwOnboarded) {
            history.push("/omw/program")
            return;
        }
    }

    return(
        <section className="bg-questions sec-hero-questions align-items-center" id="home">
          <img className="psh-logo" src={logoImage} alt="plzsend.help"/>

            {omwLive && (<a className="omw-link" href="/discord" target="_blank" rel="noreferrer">
                <p>go to omw!</p>
                <a href="/omw" target="_blank" rel="noreferrer" onClick={goToOmw}><img src={omwBordered} alt="on my way"/></a>
            </a>)}

            <a className="discord-link" href="/discord" target="_blank" rel="noreferrer">
                <p>Join our Discord!</p>
                <img className="logo" src={discordImage} alt="Join our Discord!"/>
            </a>

          <Container className="container z-index-1">
            <Row className="align-items-center justify-content-center text-center margin-mobile md-mb-4 text-fade-in-slide-up">
                  <Col xl={8} lg={10} md={10} className="align-self-start">
                      <div className="title-heading">
                          <h4 className="header mb-3">What would you like to learn about?</h4>
                              <Row className="mt-4">
                                <Col xl={8} lg={7} md={7} sm={6}>
                                    <label htmlFor="cta-hero" style={{ display: 'none' }  }>Email:</label>
                                    <input
                                        id="cta-hero"
                                        className="cta-input"
                                        type="email"
                                        placeholder="ex. analytics..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    />
                                </Col>
                                <Col xl={4} lg={5} md={5} sm={6}>
                                    <button
                                    className="btn me-1 fw-500 cta-btn"
                                    onClick={submitSearch}
                                    // disabled={buttonSubmitting}
                                    >search</button>
                                </Col>
                            </Row>
                      </div>
                  </Col>
              </Row>
              <Row className="align-items-center justify-content-center text-center margin-mobile mb-4 text-fade-in-slide-up">
                <Col lg={8} md={10} className="align-self-start">
                    {topics.map((item, index) => {
                        return <div
                                className={`card-category ${item.id == selectedTopicId && 'category-highlight'}`}
                                key={item.id}
                                onClick={(e) => {
                                    selectTopic(item.id)
                                }}
                            >{item.name.toLowerCase()} <span className="question-count">({item.question_count})</span>
                            </div>
                    })}
                </Col>
              </Row>
            <Row className={`question-container align-items-center justify-content-center text-center margin-mobile ${loading ? '' : 'show'}`}>
                {activeQuery === '' && activeTopicId === '' && <Col lg={10} className="align-self-start">
                    <p className="subheader mt-0">Our most popular questions</p>
                </Col>}
                {activeQuery !== '' && <Col lg={10} className="align-self-start">
                    <p className="subheader mt-0">Searching for "{activeQuery}"</p>
                </Col>}
                {activeTopicId !== '' && <Col lg={10} className="align-self-start">
                    <p className="subheader mt-0">All under "{topics.find(el => el.id === activeTopicId).name.toLowerCase()}"</p>
                </Col>}

                {questions.length === 1 && <Col xl={4} lg={6} md={8} sm={10} xs={12} className="align-self-start">
                    {questions.map((item, index) => {
                            return <React.Fragment key={item.id}>
                                <a href={`/question/${item.id}`} onClick={(e) => {
                                    e.preventDefault()
                                }}>
                                    <div
                                        className={`card mb-4 ${index === 1 && questions.length >= 3 ? 'mt-4' : ''}`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            history.push(`/question/${item.id}`)
                                        }}>
                                        <Row className="align-items-center justify-content-center">
                                            <Col md={9} className="text-left">
                                                <h6 className="question-header">
                                                    @{item.username} has asked...
                                                </h6>
                                                {formatQuestion(item.content)}
                                            </Col>
                                            <Col md={3} className="d-md-block d-none">
                                                <img src={anonymousPfpSelector(item.id)} className="question-img"/>
                                            </Col>
                                        </Row>
                                    </div>
                                </a>
                            </React.Fragment>                            
                            }
                        )}
                    </Col>   
                }

                {questions.length === 2 && <Col xl={8} lg={10} md={12} sm={10} xs={12} className="align-self-start">
                    <Row>
                        {questions.map((item, index) => {
                            return <React.Fragment key={item.id}>
                                <Col md={6} className="align-self-start">
                                    <a href={`/question/${item.id}`} onClick={(e) => {
                                        e.preventDefault()
                                    }}>
                                        <div
                                            className={`card mb-4 ${index === 1 && questions.length >= 3 ? 'mt-4' : ''}`}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                history.push(`/question/${item.id}`)
                                            }}>
                                            <Row className="align-items-center justify-content-center">
                                                <Col md={9} className="text-left">
                                                    <h6 className="question-header">
                                                        @{item.username} has asked...
                                                    </h6>
                                                    {formatQuestion(item.content)}
                                                </Col>
                                                <Col md={3} className="d-md-block d-none">
                                                    <img src={anonymousPfpSelector(item.id)} className="question-img"/>
                                                </Col>
                                            </Row>
                                        </div>
                                    </a>
                                </Col>
                            </React.Fragment>                            
                            }
                        )}
                    </Row>
                    
                    </Col>   
                }

                {questions.length >= 3 && 
                    <Col sm={columnWidth} className="align-self-start">
                    {/* Masonry gets a little fucked up here, so we're doin it the shitty way */}
                    <Masonry
                            breakpointCols={masonryBreakpoints}
                            className="masonry-grid"
                            columnClassName="masonry-grid_column"
                        >
                        {questions.map((item, index) => {
                            return <React.Fragment key={item.id}>
                                <a href={`/question/${item.id}`} onClick={(e) => {
                                    e.preventDefault()
                                }}>
                                    <div
                                        className={`card mb-4 ${index === 1 && questions.length >= 3 ? 'mt-4' : ''}`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            history.push(`/question/${item.id}`)
                                        }}>
                                        <Row className="align-items-center justify-content-center">
                                            <Col md={9} className="text-left">
                                                <h6 className="question-header">
                                                    @{item.username} has asked...
                                                </h6>
                                                {formatQuestion(item.content)}
                                            </Col>
                                            <Col md={3} className="d-md-block d-none">
                                                <img src={anonymousPfpSelector(item.id)} className="question-img"/>
                                            </Col>
                                        </Row>
                                    </div>
                                </a>
                            </React.Fragment>                            
                            }
                        )}
                        </Masonry>
                  </Col>
                
                }
                
                  {questions.length > 0 && <Col sm={12}>
                    <p className="subcta-text mt-2 mb-0">Still can't find the answer you're lookin for? <a href="mailto:support@plzsend.help">Email us</a> or ask in <a href="/discord" target="_blank" rel="noreferrer">Discord</a>!</p>
                  </Col>}
              </Row>
          </Container>
        </section>
    )
}

export default HomeQuestions;