import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { baseApiUrl, jwtAuthAxios, setAuthToken, getAuthToken } from "../services/request";

export const TopicContext = React.createContext();

export const TopicContextProvider = ({ children }) => {
    const [topics, setTopics] = useState([])

    const retrieveTopics = async () => {
        const {data} = await jwtAuthAxios.get(`/topics`)
        setTopics(data);
    }

    const state = {
        topics: topics,
        retrieveTopics: retrieveTopics
    }

    return (
        <TopicContext.Provider value={state}>
            {children}
        </TopicContext.Provider>
    )
}