import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { baseApiUrl, jwtAuthAxios, setAuthToken, getAuthToken } from "../services/request";

export const OmwContext = React.createContext();

import { useHistory } from 'react-router-dom';

export const OmwContextProvider = ({ children }) => {
    const history = useHistory();

    const [sessions, setSessions] = useState([])
    const [content, setContent] = useState({})

    // To handle the transition from one page to another smoothly
    const [stepLink, setStepLink] = useState(null)

    // Could there be a race condition on login? This is pretty shitty isnt it?
    // Well, figure it out and do it better
    const token = localStorage.getItem('token')

    // Going from one page to another
    const processStepLink = async () => {
        history.push(stepLink)
        requestContentIntent("open")
        setStepLink(null);
    }

    const requestContent = async (content_id) => {
        const headers = {
            headers: { Authorization: `Bearer ${token}` }
        }

        const { data } = await jwtAuthAxios.get(`/omw/content/${content_id}`, headers)

        setContent(data)
    }

    const requestContentBySlugs = async (session_slug, content_slug) => {
        const headers = {
            headers: { Authorization: `Bearer ${token}` }
        }

        const { data } = await jwtAuthAxios.get(`/omw/session/${session_slug}/content/${content_slug}`, headers)

        setContent(data)
    }

    const requestSessions = async () => {
        const headers = {
            headers: { Authorization: `Bearer ${token}` }
        }

        const { data } = await jwtAuthAxios.get(`/omw/sessions`, headers)

        setSessions(data)
    }

    // Log the users intentional actions
    // Currently only on content selection
    // Should be redundant due to Mixpanel
    const requestContentIntent = async (action) => {
        const headers = {
            headers: { Authorization: `Bearer ${token}` }
        }

        await jwtAuthAxios.post (`/omw/intent`, {
            class: 'content',
            id: content.id,
            act: action
        }, headers)
    }

    const requestIntent = async (action) => {
        const t_token = localStorage.getItem('token')
        const headers = {
            headers: { Authorization: `Bearer ${t_token}` }
        }

        await jwtAuthAxios.post (`/omw/intent`, {
            act: action
        }, headers)
    }

    const state = {
        sessions: sessions,
        content: content,
        stepLink: stepLink,
        setStepLink: setStepLink,
        processStepLink: processStepLink,
        requestSessions: requestSessions,
        requestContent: requestContent,
        requestContentBySlugs: requestContentBySlugs,
        requestIntent: requestIntent
    }

    return (
        <OmwContext.Provider value={state}>
            {children}
        </OmwContext.Provider>
    )
}