import React, { useState, useEffect } from 'react'

import {
    Row,
    Col,
  } from "reactstrap";

  import Swal from 'sweetalert2'

  import axios from 'axios';

export const EmailCta = ({buttonText}) => {
    const [email, setEmail] = useState('')
    const [buttonSubmitting, setButtonSubmitting] = useState(false);

    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-right',
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      })

    const sendEmailRequest = async (email) => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API}/email/subscribe`, {
            email: email
          }).then((response) => {
            setButtonSubmitting(false);
            setEmail('')
            Toast.fire({
              icon: 'success',
              title: "Plz check your email for next steps!"
            })
          })
        } catch (error) {
          Toast.fire({
            icon: 'warning',
            title: error.response.data.errors
          })
          setButtonSubmitting(false);
          setEmail('')
          console.log(error.response.data.errors);
        }
      }

      const validateEmail = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleKeyDown = (event) => {
      if (buttonSubmitting) {
        return;
      }
      
      if (event.key === 'Enter') {
        submitEmail(event)
      }
    }

    const submitEmail = (event) => {
      if (buttonSubmitting) {
        return;
      }

        if (email === "") {
          Toast.fire({
            icon: 'warning',
            title: "Please enter an email!"
          })
          return;
        }
    
        if (!validateEmail(email)) {
          Toast.fire({
            icon: 'warning',
            title: "Please recheck email format!"
          })
          return;
        }
    
        setButtonSubmitting(true);
        sendEmailRequest(email)
      }

    return (
        <React.Fragment>
            <Row className="mt-4">
                <Col xl={8} lg={7} md={7} sm={6}>
                    <label htmlFor="cta-hero" style={{ display: 'none' }  }>Email:</label>
                    <input
                        id="cta-hero"
                        className="cta-input"
                        type="email"
                        placeholder="your@email.here"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </Col>
                <Col xl={4} lg={5} md={5} sm={6}>
                    <button
                    className="btn me-1 fw-500 cta-btn"
                    onClick={submitEmail}
                    disabled={buttonSubmitting}
                    >{buttonText}</button>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default EmailCta;
