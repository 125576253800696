import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

import logoImage from '../assets/images/plzsendhelp-logo-256.jpg';
import {useParams} from "react-router-dom";


const Ama = () => {
    useEffect(() => {
        window.location.href = "https://tally.so/r/3N7orl";
    }, []);

    return (
        <React.Fragment>
            <section className="sec-question align-items-center">
                <a href={process.env.REACT_APP_URL}>
                    <img className="psh-logo" src={logoImage} alt="plzsend.help" onClick={() => {
                        history.push('/')
                    }}/>
                </a>
                <Container className="container z-index-1">
                    <Row className="align-items-center justify-content-center margin-mobile">
                        <Col xl={8} md={6} sm={8} xs={10} className={`vis-container show align-self-start`}>
                            {/* Keeping it like this for versatility */}
                            
                            <div className="card mt-2 mb-4">
                                <Row className={`align-items-center justify-content-center`}>
                                    <Col md={9} sm={9} className="text-left">
                                        <h5>
                                           one sec, sending you to the ama form!
                                        </h5>
                                        <Col md={12}>
                                            if that doesn't work, <a href="https://tally.so/r/3N7orl">click here</a> plz!
                                        </Col>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default Ama