import React, { useContext, useEffect } from "react";
import { UserContext } from "../contexts/UserContext"

import { useHistory } from 'react-router-dom';
import { useMixpanel } from 'react-mixpanel-browser';

import Cookies from 'js-cookie';

import axios from 'axios';

import Swal from 'sweetalert2'

const Redirect = () => {
    const { setToken } = useContext(UserContext);

    const queryString = new URLSearchParams(window.location.search)
    const code = queryString.get('code');
    const forward = queryString.get('forward')
    const history = useHistory();
    const mixpanel = useMixpanel();

    useEffect(() => {
        if (code) {
            sendCodeValidation(code)
        }
    }, [code])

    const setAuthorizationToken = (token) => {
        setToken(token)

        if (forward) {
            history.push(`/${forward}`)
            return;
        }
        history.push(`/`)
    }

    const sendCodeValidation = async (code) => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API}/email/validate`, {
            code: code
          }).then((response) => {
            const token = response.data.token
            if (token) {
                setAuthorizationToken(token)
                mixpanel.track('Login')
                Toast.fire({
                    icon: 'success',
                    title: "You're logged in! Welcome!"
                })
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: "An error occurred, please try again."
                })
                history.push(`/`)
            }
          })
        } catch (error) {
            console.log("Error!", error)
            Toast.fire({
                icon: 'warning',
                title: "Invalid login code, please try again."
            })
            history.push(`/`)
        }
      }

      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-right',
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      })

    return (
        <React.Fragment>
              <div className="redirection">
              <p>One moment please...</p>
              </div>
        </React.Fragment>
      );
  }
  
  export default Redirect;