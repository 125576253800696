import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { baseApiUrl, jwtAuthAxios, setAuthToken, getAuthToken } from "../services/request";

export const UserContext = React.createContext();
import Cookies from 'js-cookie';

import { useMixpanel } from 'react-mixpanel-browser';

export const UserContextProvider = ({ children }) => {
    const mixpanel = useMixpanel();

    const [token, setToken] = useState(null);
    const [email, setEmail] = useState(null);
    const [omwParticipant, setOmwParticipant] = useState(false);
    const [omwOnboarded, setOmwOnboarded] = useState();
    const [omwPersonalized, setOmwPersonalized] = useState(false);
    const [showPersonalized, setShowPersonalized] = useState(false);

    // TODO: Improve
    useEffect(() => {
        if (token) {
            localStorage.setItem("token", token)
            getUser(token)
        }
    }, [token])

    const getUser = async (token) => {
        // Do better
        const headers = {
            headers: { Authorization: `Bearer ${token}` }
        }

        const response = await jwtAuthAxios.get (`/user`, headers)

        if (response.data) {
            setEmail(response.data.email)
            localStorage.setItem("email", response.data.email)
            Cookies.set('email', response.data.email, { domain: '.plzsend.help', path: '/' });
            setOmwParticipant(response.data.omw_participant)
            setOmwOnboarded(response.data.omw_onboarded)
            setOmwPersonalized(response.data.omw_personalized)
            setShowPersonalized(response.data.show_personalized)
            mixpanel.identify(response.data.email)
        }
    }

    // Used to shittily signify a users "intent" to start omw
    const createSetting = async (token) => {
        // Do better
        const t_token = localStorage.getItem('token')
        const headers = {
            headers: { Authorization: `Bearer ${t_token}` }
        }

        const response = await jwtAuthAxios.post (`/omw/settings`, {}, headers)
    }

    const updateName = async (name) => {
        // Do better
        const t_token = localStorage.getItem('token')
        const headers = {
            headers: { Authorization: `Bearer ${t_token}` }
        }

        const data = await jwtAuthAxios.put (`/user`, {
            name: name
        }, headers)

        if (data.status === 200) {
            return true;
        } else {
            return false;
        }
    }

    const updateProject = async (name, website, description) => {
        // Do better
        const t_token = localStorage.getItem('token')
        const headers = {
            headers: { Authorization: `Bearer ${t_token}` }
        }

        const data = await jwtAuthAxios.put (`/omw/settings`, {
            name: name,
            website: website,
            description: description
        }, headers)

        if (data.status === 200) {
            return true;
        } else {
            return false;
        }
    }

    const updatePersonalization = async (stage, experience, goal, raise) => {
        // Do better
        const t_token = localStorage.getItem('token')
        const headers = {
            headers: { Authorization: `Bearer ${t_token}` }
        }

        const data = await jwtAuthAxios.put (`/omw/settings`, {
            stage: stage,
            experience: experience,
            goal: goal,
            raise: raise
        }, headers)

        if (data.status === 200) {
            await requestShowPersonalized(true)
            return true;
        } else {
            return false;
        }
    }

    const completeOnboarding = async () => {
        // Do better
        const t_token = localStorage.getItem('token')
        const headers = {
            headers: { Authorization: `Bearer ${t_token}` }
        }

        const data = await jwtAuthAxios.post (`/omw/settings/onboarding_completed`, {
        }, headers)

        if (data.status === 200) {
            setOmwOnboarded(true)
            return true;
        } else {
            // Error
            return false;
        }
    }


    const requestShowPersonalized = async (value) => {
        // Do better
        const t_token = localStorage.getItem('token')
        const headers = {
            headers: { Authorization: `Bearer ${t_token}` }
        }

        const data = await jwtAuthAxios.post (`/omw/settings/show_personalized`, {
            show_personalized: value
        }, headers)
    }

    const state = {
        token: token,
        omwParticipant: omwParticipant, 
        omwOnboarded: omwOnboarded,
        omwPersonalized: omwPersonalized,
        showPersonalized: showPersonalized,
        setToken: setToken,
        createSetting: createSetting,
        updateName: updateName,
        updateProject: updateProject,
        updatePersonalization: updatePersonalization,
        completeOnboarding: completeOnboarding,
        requestShowPersonalized: requestShowPersonalized
    }

    return (
        <UserContext.Provider value={state}>
            {children}
        </UserContext.Provider>
    )
}