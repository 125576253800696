import React from "react";
import Home from "../pages/Home"

import Redirect from "../pages/Redirect";
import Login from "../pages/Login";
import Discord from "../pages/Discord";
import Ama from "../pages/Ama";
const Question = React.lazy(() => import("../pages/Question"));
const TermsOfService = React.lazy(() => import("../pages/TermsOfService"));
const PrivacyPolicy = React.lazy(() => import("../pages/PrivacyPolicy")); 
const Program = React.lazy(() => import("../pages/Program"));
const ProgramOnboarding = React.lazy(() => import("../pages/ProgramOnboarding"));
const ProgramContent = React.lazy(() => import("../pages/ProgramContent"));
const LiveContents = React.lazy(() => import("../pages/LiveContents"));
const AsyncContents = React.lazy(() => import("../pages/AsyncContents"));
const Bio = React.lazy(() => import("../pages/Bio"));

const routes = [
  {
    path: "/auth/redirect",
    exact: true,
    component: Redirect
  },
  {
    path: "/",
    component: Home
  },
  {
    path: "/login",
    component: Login
  },
  {
    path: "/discord",
    component: Discord
  },
  {
    path: "/ama",
    component: Ama
  },
  {
    path: "/omw",
    exact: true,
    component: Program
  },
  {
    path: "/omw/onboarding",
    exact: true,
    component: ProgramOnboarding
  },
  {
    path: "/omw/onboarding/intro",
    exact: true,
    component: ProgramOnboarding
  },
  {
    path: "/omw/onboarding/project",
    exact: true,
    component: ProgramOnboarding
  },
  {
    path: "/omw/onboarding/outline",
    exact: true,
    component: ProgramOnboarding
  },
  {
    path: "/omw/onboarding/lets-go",
    exact: true,
    component: ProgramOnboarding
  },
  {
    path: "/omw/onboarding/personalization",
    exact: true,
    component: ProgramOnboarding
  },
  {
    path: "/omw/onboarding/personalized",
    exact: true,
    component: ProgramOnboarding
  },
  {
    path: "/omw/program", // Redirect
    exact: true,
    component: ProgramContent
  },
  {
    path: "/omw/program/:session_slug/:content_slug",
    exact: true,
    component: ProgramContent
  },
  {
    path: "/omw/bio/:tw_id",
    exact: true,
    component: Bio
  },
  {
    path: "/omw/live-crew",
    exact: true,
    component: LiveContents
  },
  {
    path: "/omw/async",
    exact: true,
    component: AsyncContents
  },
  {
    path: "/question/:question_id",
    exact: true,
    component: Question
  },
  {
    path: "/terms-of-service",
    exact: true,
    component: TermsOfService
  },
  {
    path: "/privacy-policy",
    exact: true,
    component: PrivacyPolicy
  },
  {
    path: "/omw/*",
    exact: false,
    component: Program
  }
];

export default routes;
