import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { baseApiUrl, jwtAuthAxios, setAuthToken, getAuthToken } from "../services/request";

export const SearchContext = React.createContext();

export const SearchContextProvider = ({ children }) => {
    const [activeQuery, setActiveQuery] = useState('')
    const [activeTopicId, setActiveTopicId] = useState('')

    const [questions, setQuestions] = useState([])

    const retrieveLandingQuestions = async () => {
        let {data} = await jwtAuthAxios.get(`/landing`)

        // Error handling if zero?
        setQuestions(data)
    }

    const requestSearch = async (searchQuery) => {
        setActiveQuery(searchQuery);
        setActiveTopicId('')
        let { data } = await jwtAuthAxios.get(`/search?search_query=${searchQuery}`)

        setQuestions(data)

        console.log("What was returned?", data)

    }

    const requestTopicSearch = async (topicId) => {
        setActiveQuery('')
        setActiveTopicId(topicId)

        const { data } = await jwtAuthAxios.get(`/search?topic_id=${topicId}`)

        setQuestions(data)

        console.log("topic search", data)
    }

    const state = {
        activeQuery: activeQuery,
        activeTopicId: activeTopicId,
        setActiveTopicId: setActiveTopicId,
        retrieveLandingQuestions: retrieveLandingQuestions,
        requestTopicSearch: requestTopicSearch,
        requestSearch,
        questions: questions
    }

    return (
        <SearchContext.Provider value={state}>
            {children}
        </SearchContext.Provider>
    )
}