import jwtAxios from "axios";

export const jwtAuthAxios = jwtAxios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        'Content-Type': 'application/json'
    }
});

jwtAuthAxios.interceptors.response.use(
    res => res,
    err => {
        if(err.response.status === 401) {
            console.log("401!!");
            window.location.href = process.env.REACT_APP_URL + "/login"

        }
        if(err.response.status === 402) {
            // TODO: Something
            console.log("402!!")
            // window.location.href = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_URL
        }
        if(err.response.status === 404) {
            console.log("404!!")
            // window.location.href = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_URL
        }
        return Promise.reject(err);
    }
);

// TODO: Figure out why this is breaking sometimes
export const setAuthToken = (token) => {
    if(token) {
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        sessionStorage.setItem('token', token);
        // if (process.env.NODE_ENV === 'development') {
        //     Cookies.set('token', token, { domain: '.localdev' });
        // } else {
        //     Cookies.set('token', token, { domain: '.logg.fi' });
        // }
        
    } else {
        delete jwtAuthAxios.defaults.headers.common['Authorization'];
        sessionStorage.removeItem('token')
        // Cookies.remove('token')
    }
};

export const getAuthToken = () => {
    return localStorage.getItem("token")
    // return Cookies.get('token')
};
