import React, { useContext, useState, useEffect } from "react";
import Masonry from 'react-masonry-css';
import {
    Container,
    Row,
    Col,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
  } from "reactstrap";

  import logoImage from '../assets/images/plzsendhelp-logo-256.jpg';

  import { SearchContext } from "../contexts/SearchContext"
  import { UserContext } from "../contexts/UserContext"
  import { TopicContext } from "../contexts/TopicContext"
  import { QuestionContext } from "../contexts/QuestionContext"

  import { useHistory } from 'react-router-dom';

export const HomeJourney = () => {

    const [accordionOpen, setAccordionOpen] = useState('');
    const toggleAccordion = (id) => {
      if (accordionOpen === id) {
        setAccordionOpen();
      } else {
        setAccordionOpen(id);
      }
    };

    return(
        <React.Fragment>
            <section style={{position: 'relative'}}>
                <div className="custom-shape-divider-top-1687720502-2">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill" style={{ fill: "#ffdf3c" }}></path>
                    </svg>
                </div>
            </section>
            
            <section className="sec-journey">
                <Container>
                    <Row className="align-items-center justify-content-center margin-mobile">
                    <Col lg={7} md={8} sm={12}>
                        <div className="card mt-5 mb-4 pb-2">
                            <p className="subtitle">
                            <h4 style={{ fontWeight: '500'}}>So where are you in your journey?</h4>
                            </p>

                            <p className="subtitle mt-1">
                                If you tell us where you are in your project, we’ll guide you towards the right resources to help you get started. 
                            </p>

                            <p className="subtitle mt-1">
                                Theres a lot you <strong>could</strong> be thinking about, but frankly, theres only a few things you <strong>should</strong> be thinking about at any point in time. 
                            </p>

                            <p className="subtitle mt-1">
                                Let's focus on what’s one step in front of you, not ten steps down the road. 
                            </p>

                            <Accordion flush open={accordionOpen} toggle={toggleAccordion}>
                                <AccordionItem>
                                <AccordionHeader targetId="1">I have an idea, but only an idea</AccordionHeader>
                                <AccordionBody accordionId="1">

                                    <p>At this stage, there are only a few major considerations you really need to be making:</p>

                                    <ul>
                                        <li>Is my idea worth building?</li>
                                        <li>Can I actually build this?</li>
                                        <li>Where do I even start?</li>
                                    </ul>

                                    <p>Validating your idea now should be your most important focus, in order to prevent you from spending your time on a product that nobody ends up wanting.</p>

                                    <ul>
                                        <li>
                                            <a href="https://plzsend.help/question/9e5905e5-0679-4adb-8ad8-2b57fa9de9be" target="_blank" rel="noreferrer">
                                                What does it mean to validate an idea?
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://plzsend.help/question/a1746a34-8bcc-47db-af7d-912aa260c604" target="_blank" rel="noreferrer">
                                                When should I get feedback?
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://plzsend.help/question/1381f67a-f1d8-4c6d-9443-5661ed7d9e7e" target="_blank" rel="noreferrer">
                                                How do I know when my idea is good enough to start building?
                                            </a>
                                        </li>
                                    </ul>

                                    <p>
                                        Many solo founders also often start to wonder if they're capable on their own, or <a href="https://plzsend.help/question/aa9b63d7-1946-4459-bef6-6bf468a70060" target="_blank" rel="noreferrer">if they need a co-founder</a>. But it's fine if you don't know everything about the market, or if you aren't ready to build. Most people arent.
                                    </p>

                                    <p>
                                        That just means your next efforts to validate your idea is more than likely just by talking with your potential customers.
                                    </p>

                                    <ul>
                                        <li>
                                            <a href="https://plzsend.help/question/27c6f176-895f-4925-9c15-eeef9056ef63" target="_blank" rel="noreferrer">
                                                Why should I communicate with customers?
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://plzsend.help/question/91051f57-40c9-4560-93c0-8bae2b141740" target="_blank" rel="noreferrer">
                                                When do I first communicate with customers?
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://plzsend.help/question/c1bf1c5d-1f2a-45d1-8754-05b7974b29ea" target="_blank" rel="noreferrer">
                                                How do I communicate with customers?
                                            </a>
                                        </li>
                                    </ul>

                                    <p>
                                        After a number of cold reachouts and conversations, you should be in a better position to understand if the solution you're wanting to build solves an actual problem and has people that are interested in it.
                                    </p>

                                    <p>Then, we start building!</p>
                                </AccordionBody>
                                </AccordionItem>

                                <AccordionItem>
                                <AccordionHeader targetId="2">My idea is validated, do I start now?</AccordionHeader>
                                <AccordionBody accordionId="2">
                                    <p>
                                        Got some good feedback? Great! Now's probably the time you should plan out <a href="https://plzsend.help/question/eaab95df-4630-415a-be35-f477d5bae65d" target="_blank" rel="noreferrer">how to build your MVP</a>.
                                    </p>

                                    <p>If you have a landing page, now you'll really need to start considering your <a href="https://plzsend.help/question/55af543a-5b71-4a29-8880-923728fab167" target="_blank" rel="noreferrer">branding and voice</a> with regards to how you communicate to customers, which is also reflective in <a href="https://plzsend.help/question/29e74608-c867-473f-8765-039ec4c8dbd2" target="_blank" rel="noreferrer">your sales copy</a>.</p>


                                    <p>
                                        As you're starting off, it will be good to familiarize yourself with the basics of analytics tools in order to monitor traffic and analyze user behaviour.
                                    </p>
                                    <ul>
                                        <li>
                                            <a href="https://plzsend.help/question/914650ef-bb70-421c-aa37-4f51635c7bc6" target="_blank" rel="noreferrer">
                                                Should I have analytics for my website/app?
                                            </a>
                                        </li>
                                        <li>   
                                            <a href="https://plzsend.help/question/d1a820d8-8225-412b-8819-d05c573d24e5" target="_blank" rel="noreferrer">
                                                What kind of analytics should I have in my website/app?
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://plzsend.help/question/58860d7f-c1fe-4966-b0e4-6ca3fac83380" target="_blank" rel="noreferrer">
                                                What kind of backend analytics should I have in my app?
                                            </a>
                                        </li>
                                    </ul>
                                    <p>That being said, you don't need all of the above just yet. Something like Google Analytics + HotJar will be enough for a while.</p>

                                    <p>One question that comes up frequently at this stage is <a href="https://plzsend.help/question/bf6e621b-83b7-42b4-af2d-ba8717510f16" target="_blank" rel="noreferrer">"should I raise money?"</a>. This is often because of a lack of resources, but it's a consideration that should not be taken lightly.</p>

                                    <p>At the same time, you should also understand that this isn't going to be a short and quicky journey. If you <a href="https://plzsend.help/question/84d8032a-038d-4d54-a210-04c124649d21" target="_blank" rel="noreferrer">need to make money right now</a> to survive, creating a startup should not be your focus.</p>
                                </AccordionBody>
                                </AccordionItem>

                                <AccordionItem>
                                <AccordionHeader targetId="3">I’ve started working on my project</AccordionHeader>
                                <AccordionBody accordionId="3">
                                    <p>As you're progressing, you'll want to always keep an open feedback loop with your users to ensure you're moving in the right direction.</p>

                                    <p>As you get closer to a launch (or beta/early access), there are some basic concepts that you should keep in mind</p>
                                    <ul>
                                        <li>
                                            <a href="https://plzsend.help/question/3837f017-047c-4446-9be8-a043a31a1aeb" target="_blank" rel="noreferrer">
                                                What is a user funnel?
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://plzsend.help/question/66931a4c-2138-4480-94b6-77d3d702f005" target="_blank" rel="noreferrer">
                                                What is "friction"?
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://plzsend.help/question/026bc191-ebff-47ff-9af3-b8d073544572" target="_blank" rel="noreferrer">
                                                How do I price my product?
                                            </a>
                                        </li>
                                    </ul>

                                    <p>
                                        A realization that often hits founders too late is that marketing is not actually easy, and that it takes far longer than anyone would want. We suggest <a href="https://plzsend.help/question/7a5649d9-fd05-405a-b570-156cd164e381" target="_blank" rel="noreferrer">building your own personal brand</a> on social media, as you'd be building your own distribution network.
                                    </p>

                                    <p>
                                        Also be aware that there are others that have likely wandered into your space before you, and there's much to be <a href="https://plzsend.help/question/6b004ba7-a399-49e8-86f1-a4a545dae645" target="_blank" rel="noreferrer">learned from studying your competitors</a>.
                                    </p>
                                </AccordionBody>
                                </AccordionItem>

                                <AccordionItem>
                                <AccordionHeader targetId="4">I got an MVP but now I need to make money</AccordionHeader>
                                <AccordionBody accordionId="4">
                                    <p>Now that you've gotten a product into the wild, your goals are to increase awareness and drive users.</p>

                                    <p>If you haven't communicated with any users yet, read the previous steps. But if you have, you should already have your first handful of users in the bag.</p>

                                    <p>Some founders often believe that the easiest way to drive traffic is with <a href="https://plzsend.help/question/61bf6d09-5cec-4ccc-8a32-eb2cccd4d247" target="_blank" rel="noreferrer">paid ads</a>, but I'd be hesitant as it can end up being quite costly.</p>

                                    <p>A key element to driving users and increasing usage is by ensuring that your user experience is comfortable and welcoming for both new and old users.</p>

                                    <p>One of the simplest ways to set the right experience for your user is to create a welcome campaign that thanks them for signing up.</p>

                                    <ul>
                                        <li>
                                            <a href="https://plzsend.help/question/706c546c-3f71-44de-b758-66741fe1f6c7" target="_blank" rel="noreferrer">
                                                Do I need email campaigns?
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://plzsend.help/question/f0e3643d-df84-4a34-ab74-12728f0b4f49" target="_blank" rel="noreferrer">
                                                What kind of email campaigns should I have?
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://plzsend.help/question/50452b3e-d081-4e5d-a043-5c60d0afc342" target="_blank" rel="noreferrer">
                                                Should I have an email campaign for when users sign up?
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://plzsend.help/question/1a466dd5-280d-4873-9db5-0718349176ba" target="_blank" rel="noreferrer">
                                                What should my welcome/onboarding campaign look like?
                                            </a>
                                        </li>
                                    </ul>

                                    <p>Another huge area of opportunity is the onboarding process that all of your users will see when they first sign up.</p>

                                    <ul>
                                        <li>
                                            <a href="https://plzsend.help/question/93b29a02-47bd-43c1-94f2-54629b56b69b" target="_blank" rel="noreferrer">
                                                Do I need an onboarding process?
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://plzsend.help/question/adada0dd-227a-4f28-a33e-f8fc37dc9c5b" target="_blank" rel="noreferrer">
                                                What should my onboarding process look like?
                                            </a>
                                        </li>
                                    </ul>

                                    <p>And if you're giving them a wonderful experience, now might be the right moment to <a href="https://plzsend.help/question/948a062c-94a6-4715-9c9a-dde14490bc61" target="_blank" rel="noreferrer">ask them to upgrade</a>!</p>
                                </AccordionBody>
                                </AccordionItem>

                                {/* <AccordionItem>
                                <AccordionHeader targetId="5">I’m makin some money but I want to do better</AccordionHeader>
                                <AccordionBody accordionId="5">
                                    <a href="https://plzsend.help/question/64276fcc-e570-43c6-9479-1945358eff46">
                                        How do I visualize my analytics data?
                                    </a>


                                    <a href="https://plzsend.help/question/0db9dcfa-b5b1-40d5-8577-48c022450556">
                                        Should I have case studies?
                                    </a>

                                    <a href="https://plzsend.help/question/2e35aebf-da6b-47d0-9278-6b6cea43336e">
                                        What is A/B testing and do I need it?
                                    </a>
                                </AccordionBody>
                                </AccordionItem> */}
                            </Accordion>
                            <p className="subtitle mt-3 mb-1" style={{ fontSize: '14px', textAlign: 'center'}}>
                                As with much of plzsend.help, we are updating the content above regularly.
                            </p>
                            <p className="subtitle mb-0" style={{ fontSize: '14px', textAlign: 'center'}}>
                                Always feel free to <a href="mailto:support@plzsend.help">email us</a> for anything specific
                            </p>
                        </div>
                    </Col>
                    </Row>
                </Container>
                </section>

                <section style={{ position: 'relative' }}>
                <div className="custom-shape-divider-top-1687778966">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
                </section>
        </React.Fragment>
    )
}

export default HomeJourney;